import { Component } from '@angular/core';
import { faceFadeIn } from '../animations';

@Component({
  selector: 'app-newfaceblock',
  templateUrl: './newfaceblock.component.html',
  styleUrls: ['./newfaceblock.component.css'],
  animations: [faceFadeIn]
})
export class NewfaceblockComponent {

  constructor() { }



}
