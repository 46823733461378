import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MainComponent } from './main/main.component';
import { GrafikblockComponent } from './grafikblock/grafikblock.component';
import { LetterblockComponent } from './letterblock/letterblock.component';
import { MottoblockComponent } from './mottoblock/mottoblock.component';
import { VpblockComponent } from './vpblock/vpblock.component';
import { ProjectsblockComponent } from './projectsblock/projectsblock.component';
import { LogosblockComponent } from './logosblock/logosblock.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PersonComponent } from './person/person.component';
import { BranchenComponent } from './branchen/branchen.component';
import { MethodenComponent } from './methoden/methoden.component';
import { ConnectComponent } from './connect/connect.component';
import { AddmarginDirective } from './addmargin.directive';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CallpopupComponent } from './callpopup/callpopup.component';
import { FadeinfromDirective } from './fadeinfrom.directive';
import { NewfaceblockComponent } from './newfaceblock/newfaceblock.component';
import { AnimflagDirective } from './animflag.directive';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MainComponent,
    GrafikblockComponent,
    LetterblockComponent,
    MottoblockComponent,
    VpblockComponent,
    ProjectsblockComponent,
    LogosblockComponent,
    PersonComponent,
    BranchenComponent,
    MethodenComponent,
    ConnectComponent,
    AddmarginDirective,
    CallpopupComponent,
    FadeinfromDirective,
    NewfaceblockComponent,
    AnimflagDirective
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    FontAwesomeModule
  ],
  providers: [

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
