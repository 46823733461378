<section class="bgWhite">
    <div>
        <h2 class="marBottom">Darum wollen wir uns kennenlernen </h2>

        <div class="letter" [@sctFadeIn]="sctFadeIn" *ngIf="inView === true">

            <p>Die Möglichkeiten ihr Geschäft zu verändern und zukunftsfähig zu gestalten sind unerschöpflich. Sicher arbeiten auch Sie an der kontinuierlichen Verbesserung ihres Leistungsangebotes, an wegweisenden Innovationen und dem Erschließen neuer
                Märkte, Marktsegmente oder Kundengruppen. Zeitgleich suchen und finden Sie Lösungen für die Verbesserung der Kundenbindung oder der allgemeinen Prozesslandschaft, während dessen Sie sich intensiv mit den Chancen der Digitalisierung oder
                disruptiver Geschäftsmodelle auseinandersetzen.</p>

            <p>Wenn Sie die Leitung eines Unternehmens oder Geschäftsbereiches eines klein- oder mittelständischen Unternehmens verantworten, dann wird es jedoch Themen geben, bei denen Sie sich <strong>mehr Intensität</strong>, <strong>Sorgfalt</strong>                oder <strong>Kompetenz</strong> wünschen. Möglicherweise brauchen Sie jemanden, der diese Leistungslücke schließt, der Verantwortung <em>on the fly</em> übernehmen kann, mit <strong>Konzept- und Umsetzungskompetenzen</strong> ausgestattet
                ist und sich, bei Bedarf weder vor dem Kundenkontakt noch vor der <strong>Programmierung
                digitaler Prototypen</strong> scheut. Dann kommen Sie auf mich zu!</p>

            <p>Ich freue mich auf Sie. </p>
            <p> <cite>Christian Winkler</cite></p>

        </div>




    </div>
</section>