import { Component, OnInit, Input, HostBinding, HostListener, ElementRef, OnChanges, ViewChild, Renderer2 } from '@angular/core';
import { fadeInLefti } from '../animations';

@Component({
  selector: 'app-mottoblock',
  templateUrl: './mottoblock.component.html',
  styleUrls: ['./mottoblock.component.css'],
  animations: [fadeInLefti]
})
export class MottoblockComponent implements OnInit {

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() { }




}
