import { Component, OnInit, Input, ElementRef, OnChanges } from '@angular/core';
import { sctFadeIn } from '../animations';
import { WindowRefService } from '../window-ref.service';


@Component({
  selector: 'app-vpblock',
  templateUrl: './vpblock.component.html',
  styleUrls: ['./vpblock.component.css'],
  animations: [sctFadeIn]
})
export class VpblockComponent implements OnInit {
  sctFadeIn;
  inView = false;
  elRefi;


  constructor(private el: ElementRef) {

  }

  ngOnInit() {
    this.elRefi = this.el;
  }


}
