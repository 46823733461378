import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PlattformService {
  thisIsPlatformBrowser = false;
  thisIsPlatformserver = false;


  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {

    if (isPlatformBrowser(this.platformId)) {
      this.thisIsPlatformBrowser = true;
      this.thisIsPlatformserver = false;
    }
    if (isPlatformServer(this.platformId)) {
      this.thisIsPlatformserver = true;
      this.thisIsPlatformBrowser = false;
    }
  }



}
