import { Component, OnInit } from '@angular/core';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { WindowRefService } from '../window-ref.service';

@Component({
  selector: 'app-callpopup',
  templateUrl: './callpopup.component.html',
  styleUrls: ['./callpopup.component.css']
})
export class CallpopupComponent implements OnInit {

  faEnvelope = faEnvelope;
  faPhone = faPhone;
  faArrowUp = faArrowUp;

  constructor(private window: WindowRefService) { }

  ngOnInit(): void {
  }

  scrollBack() {

    this.window.nativeWindow().scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });


  }


}
