
import {
    trigger, style, animate, transition, query, group, keyframes, state, useAnimation, stagger,
    animateChild
} from '@angular/animations';
import { bounce, fadeInLeft, tada, jello } from 'ngx-animate';
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons';

// DrehLogo
export let turnit = trigger('turnit', [

    transition('void => *', [
        query('.arrow, .plus', style({ opacity: 0 })),
        query('.circle', style({ opacity: 0, width: 0 })),

        group([
            query('.arrow', animate('1.5s ease-in', style({ opacity: 1, transform: 'rotate(720deg)' }))),
            query('.plus', animate('1.5s ease-in', style({ opacity: 1, transform: 'rotate(360deg)' }))),
            query('.circle',
                animate('1.5s ease-in',
                    keyframes([
                        style({ opacity: 0.1, transform: 'rotate(-720deg)' }),
                        style({ opacity: 0.4, transform: 'scale(1.3)' }),
                        style({ opacity: 0.7, transform: 'scale(1.0)' })
                    ])
                ))
        ]),
    ]),
]);

// digi/classic Overlay
export let faceFadeIn = trigger('faceFadeIn', [

    transition(':enter, :leave', [
        query('#classic', style({ opacity: 0 })),
        query('#digital', style({ opacity: 0 })),
        query('#classic', animate('0.5s 0.5s ease-in', style({ opacity: 1 }))),
        query('#digital', animate('0.5s ease-in', style({ opacity: 1 }))),
    ])
]);


// section Fade-Inns

export let sctFadeIn = trigger('sctFadeIn', [

    transition(':enter', [
        style({ opacity: 0, height: 0, transform: 'translateX(-20px)' }),
        animate('.8s ease-out', style({ opacity: 1, height: '*', transform: '*' }))
    ])
]);

// für call popup/slide in
export let fromTop = trigger('fromTop', [

    transition(':enter', [
        style({ opacity: 0, transform: 'translatey(-50px)' }),
        animate('.8s  ease-in', style({ opacity: 1, transform: '*' }))
    ])
]);


export let gardine = trigger('gardine', [

    transition('void => *', [
        style({ opacity: 0 }),
        animate('2s', style({ opacity: 1 }))
    ])
]);


// ProjektBox (stagger geklaut)

export let moreFadeIn = trigger('fade', [
    transition('*=>*', [style({ opacity: 0 }), animate('1s ease')])
]);

export let staggerTry = trigger('stagger', [
    transition(':enter', [
        query(':enter', stagger('.1s', [animateChild()]))
    ])
]);
export let staggerLogo = trigger('stagger', [
    transition('*=>*', [
        query('img', stagger('.1s', [animateChild()]))
    ])
]);


// VON NGX ANIMATE

export let bouncee = trigger('bounce', [transition('* => *', useAnimation(bounce,
    {
        // Set the duration to 5seconds and delay to 2seconds
        params: { timing: 5, delay: 2 }
    }))]);

// MottoBox

export let fadeInLefti = trigger('fadeInLeft', [
    state('void', style({ opacity: 0 })),
    transition('* => *', useAnimation(fadeInLeft,
        {
            // dauert 1s, verzögert um 1sec, kommt von einem Punkt der -20px vom Zielort entfernt ist
            params: { timing: 1, delay: 0, a: '-20px' },

        }))]);

export let tadai = trigger('tada', [

    // state('void', style({ opacity: 0 })),

    transition('* => *', useAnimation(tada,
        {
            // Set the duration to 5seconds and delay to 2seconds
            params: { timing: 0.5, delay: 0 }
        }))]);




