import { Component, OnInit, Input, ElementRef, AfterViewInit } from '@angular/core';


import {
  trigger,
  state,
  style,
  animate,
  transition,

} from '@angular/animations';

@Component({
  selector: 'app-grafikblock',
  templateUrl: './grafikblock.component.html',
  styleUrls: ['./grafikblock.component.css'],
  animations: [

    trigger('thisFadeIn', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(-20px)' }),
        animate('.8s  ease-in', style({ opacity: 1, transform: '*' }))
      ])
    ])]
})

export class GrafikblockComponent implements OnInit, AfterViewInit {
  thisFadeIn;
  inView = false;
  elRefi;

  constructor(private el: ElementRef) { }
  ngAfterViewInit() { }

  ngOnInit() {
    this.elRefi = this.el;
  }



}
