import { Component, OnInit, ElementRef } from '@angular/core';
import { sctFadeIn } from '../animations';


@Component({
  selector: 'app-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.css'],
  animations: [sctFadeIn]
})
export class ConnectComponent implements OnInit {
  sctFadeIn;
  inView = false;
  elRefi;

  constructor(private el: ElementRef) { }

  ngOnInit() {
    this.elRefi = this.el;
  }
}
