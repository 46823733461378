import { Component, OnInit, Input, ElementRef, OnChanges, ViewChild } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition, query, stagger
  // ...
} from '@angular/animations';

import { tadai, moreFadeIn, staggerTry, sctFadeIn } from '../animations';
import { WindowRefService } from '../window-ref.service';

@Component({
  selector: 'app-projectsblock',
  templateUrl: './projectsblock.component.html',
  styleUrls: ['./projectsblock.component.css'],
  animations: [tadai, moreFadeIn, staggerTry, sctFadeIn]
})
export class ProjectsblockComponent implements OnInit, OnChanges {
  classic = false;
  digital = false;
  tadaClassic = false;
  tadaDigital = false;
  sctFadeIn;
  selected = 'rgba(0, 255, 0, 1)';
  selectedClas = false;
  selectedDig = false;
  inView = false;
  elRefi;

  @Input() checkOn;

  classicProjects = [
    'Aufbau Produktmanagement für Entwicklungsdienstleistung',
    'Konzeption und Durchführung diverser Vertriebskampagnen',
    'Etablierung eines Innovatiosprozesses',
    'Aufbau und Leitung eines Europa-weiten New Business Development Teams',
    'Durchführung eines internationalen VoCs (Voice of the Customer) zur Geschäftsfeld-Evaluierung',
    'Spezifizierung, Identifikation und Übernahme eines Akquisitionszieles',
    'Key Account Management für diverse Global Player',
    'Vertriebsleitung'
  ];

  digitalProjects = [
    'Konzeption und Launch des Start-Ups T-Battle - eine App-unterstütze Tennis-Liga die das Verabreden unter Spielern vereinfachen soll',
    'Entwicklung von Logopad, eines interaktiven Kartenspiels für Logopädiepatienten',
    'App für Konzertbesucher (Angular, Firebase)',
    'Online Platform für digitale Speisekarten (Angular, MySQL)',
    'Konzeption und Ausrollen neue Web-Präsenz inkl. neuer CI/CD',
    'Spezifikation und Projektleitung neue KontaktManagement-Software',
    'Durchführung eines Hackathons',
    'Konzeption und Umsetzung von Facebook-/Instagram-/LinkedIn-Vertriebs-Kampagnen'
  ];
  @ViewChild('scrollView') scrollView: ElementRef;

  constructor(private el: ElementRef) { }

  ngOnInit() {
    this.elRefi = this.el;
  }

  ngOnChanges() {

    this.inView = this.checkOn;
  }

  toggleClassic() {
    this.tadaClassic = !this.tadaClassic;
    this.classic = !this.classic;
    this.digital = false;
    if (this.classic === true) {
      this.scrollView.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      this.selectedDig = false;
      this.selectedClas = true;
    } else { this.selectedClas = false; }
  }
  toggleDigital() {
    this.tadaDigital = !this.tadaDigital;
    this.digital = !this.digital;
    this.classic = false;
    if (this.digital === true) {
      this.scrollView.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      this.selectedClas = false;
      this.selectedDig = true;
    } else { this.selectedDig = false; }
  }
}
