<section class="bgWhite">

    <h2 class="marBottom">Projektgeber / Referenzen</h2>

    <div fxLayout="row wrap" fxLayoutAlign="space-around center" *ngIf="inView === true" @stagger>


        <div><img @fade src="../../assets/pics/iveco_logo_xs.png " alt="Logo Iveco"></div>
        <div><img @fade src="../../assets/pics/ibm_logo_xs.png " alt="Logo IBM"></div>
        <div><img @fade src="../../assets/pics/grupoantolin_logo_xs.png" alt="Logo GrupoAntolin"></div>
        <div><img @fade src="../../assets/pics/watlow_logo_xs.png" alt="Logo Watlow"></div>
        <div><img @fade src="../../assets/pics/tbattle_logo_xs.png" alt="Logo T-Battle"></div>
        <div><img @fade src="../../assets/pics/magnaelectronics_logo_xs.png" alt="Logo Magna Electronics"></div>
        <div><img @fade src="../../assets/pics/smart_logo_xs.png" alt="Logo SMART"></div>
        <div><img @fade src="../../assets/pics/wik_logo_xs.png" alt="Logo WIK-Group"></div>

    </div>
</section>