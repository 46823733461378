import { Component, OnInit, ElementRef } from '@angular/core';
import { sctFadeIn } from '../animations';

@Component({
  selector: 'app-branchen',
  templateUrl: './branchen.component.html',
  styleUrls: ['./branchen.component.css'],
  animations: [sctFadeIn]
})
export class BranchenComponent implements OnInit {
  sctFadeIn;
  inView = false;
  elRefi;

  constructor(private el: ElementRef) { }

  ngOnInit() {

    this.elRefi = this.el;
  }
}
