<div id="blocki" fxLayout="row" fxLayoutAlign="space-around center">

    <a (click)="scrollBack()" id="back" class="white">
        <fa-icon [icon]="faArrowUp"></fa-icon>
    </a>

    <a href="tel:+4917652364030" id="phone" class="white">
        <fa-icon [icon]="faPhone"></fa-icon>
        <!-- <fa-icon [icon]="faPhone" [pulse]="true"></fa-icon> -->
    </a>

    <a href="mailto:cwinkler@we-battle.com" id="mail" class="white">
        <fa-icon [icon]="faEnvelope"></fa-icon>
    </a>
</div>