<section class="bgGrey">
    <div fxLayoutAlign="center">
        <h2 #scrollView class="white marBottom">Beispiel-Projekte</h2>
    </div>

    <div [@sctFadeIn]="sctFadeIn" *ngIf="inView === true">

        <div fxLayout="row " fxLayoutAlign="space-around center">
            <h2 [@tada]="tadaClassic" (click)="toggleClassic()" class="white border size" [style.background-color]="selectedClas? selected:'transparent'">klassisch</h2>
            <div id="clickit" class="white ">
                click it!
            </div>

            <h2 [@tada]="tadaDigital" (click)="toggleDigital()" class="white border size" [style.background-color]="selectedDig? selected:'transparent'">digital</h2>
        </div>

        <div *ngIf="classic===true " class="white ">

            <div @stagger>
                <div @fade *ngFor="let project of classicProjects ">

                    <div class='item'>
                        {{project}}
                    </div>
                </div>
            </div>

        </div>

        <div *ngIf="digital===true " class="white ">

            <div @stagger>
                <div @fade *ngFor="let project of digitalProjects ">

                    <div class='item'>
                        {{project}}
                    </div>
                </div>
            </div>

        </div>


    </div>


</section>