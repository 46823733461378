import { Component, OnInit, ElementRef } from '@angular/core';
import { sctFadeIn } from '../animations';

@Component({
  selector: 'app-methoden',
  templateUrl: './methoden.component.html',
  styleUrls: ['./methoden.component.css'],
  animations: [sctFadeIn],

})
export class MethodenComponent implements OnInit {
  sctFadeIn;
  inView = false;
  elRefi;

  constructor(private el: ElementRef) { }

  ngOnInit() {
    this.elRefi = this.el;
  }
}
