
import { Component, OnInit, ElementRef } from '@angular/core';
import { sctFadeIn } from '../animations';



@Component({
  selector: 'app-letterblock',
  templateUrl: './letterblock.component.html',
  styleUrls: ['./letterblock.component.css'],
  animations: [sctFadeIn]
})
export class LetterblockComponent implements OnInit {
  sctFadeIn;

  inView = false;
  elRefi;

  constructor(private el: ElementRef) { }

  ngOnInit() {
    this.elRefi = this.el;
  }


}
