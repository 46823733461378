<section class="bgGrey">
    <div fxLayoutAlign="center">
        <h2 class="bigTypo white">Wobei ich unterstützen kann</h2>
    </div>

    <div fxLayoutAlign="center">

        <div id="imageblock">

            <img src="../../assets/pics/support_circle.svg" src.xs="../../assets/pics/grafics_xs.PNG" alt="Leistungspotential digital, klassisch bei Konzept und Umsetzung strategischer Projekte">

            <!-- <div *ngIf="fxHide.xs===false"> -->
            <div *ngIf="inView===true">
                <div fxHide.xs>
                    <div [@thisFadeIn]="thisFadeIn" id="konzept" class="white bgGrey bigTypo">Konzeption</div>
                    <div [@thisFadeIn]="thisFadeIn" id="umsetzung" class="white bgGrey bigTypo">Umsetzung</div>
                    <div [@thisFadeIn]="thisFadeIn" id="classic" class="white bgGrey bigTypo">klassisch</div>
                    <div [@thisFadeIn]="thisFadeIn" id="digital" class="white bgGrey bigTypo">digital</div>
                </div>
            </div>
        </div>
        <!-- </div> -->

    </div>

</section>