<section class="bgGrey">
    <div fxLayoutAlign="center">
        <h2 class="white marBottom">Branchen</h2>
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around" *ngIf="inView === true" [@sctFadeIn]="sctFadeIn">
        <ul class="green">
            <li>Automobil Zulieferindustrie </li>
            <li>Haushaltsgeräte-Entwicklung-/ Produktion</li>
            <li>Abgasnachbehandlung</li>
            <li>Web/App-Entwicklung / IT</li>
            <li>Musik-Lehre</li>
        </ul>
        <ul class="green">

            <li>Elektrotechnik</li>
            <li>Elektronik </li>
            <li>Tennissport</li>
            <li>Logopädie</li>
            <li>Bürogeräte </li>

        </ul>
    </div>

</section>