<section class="bgGrey">
    <div fxLayoutAlign="center">
        <h2 class="white marBottom">Sonstiges zu meiner Person </h2>
    </div>
    <div fxLayout="row" fxLayoutGap="20px" fxLayout.xs="column" fxLayoutAlign="space-around" *ngIf="inView === true" [@sctFadeIn]="sctFadeIn">

        <ul class="green">
            <li>Dipl.-Wirtschafts-Ingenieur </li>
            <li>Geboren 1974 </li>
            <li>Berufserfahrung >20 Jahre</li>
            <li>Sprachen: deutsch, englisch, spanisch, schweizerdeutsch</li>

        </ul>

        <ul class="green">
            <li>Berufliche Wirkstätten: Ravensburg, Mülheim, Essen, Aschaffenburg, NewYork, Madrid, Burgos</li>
            <li>geprägt durch das produzierende Gewerbe </li>
            <li>inspiriert durch digitale Möglichkeiten</li>
        </ul>

    </div>
</section>