<section class="bgWhite">

    <h2 class="grey marBottom">Womit ich unterstützen kann</h2>
    <div fxLayout="column" fxLayoutAlign="center center" *ngIf="inView === true">
        <div [@sctFadeIn]="sctFadeIn" class="bigTypo">Geschäftsfeldentwicklung</div>
        <div [@sctFadeIn]="sctFadeIn" class="bigTypo">Unternehmens- / Geschäftsfeld-Transformation</div>
        <div [@sctFadeIn]="sctFadeIn" class="bigTypo">strategische Projektleitung</div>
        <div [@sctFadeIn]="sctFadeIn" class="bigTypo">Entwicklung Web- / App Lösungen</div>
    </div>

</section>