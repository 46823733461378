<header class="bgGrey" fxLayout="row" fxLayoutAlign="space-between center">
    <div>
        <div @turnit>
            <img class="arrow" src="../../assets/pics/logo_arrow_rechts.svg" alt="Logo Christian Winkler Pfeil">
            <img class="circle" src="../../assets/pics/logo_circle_rechts.svg" alt="Logo Christian Winkler Kreis">
            <img class="plus" src="../../assets/pics/logo_plus_rechts.svg" alt="Logo Christian Winkler Plus">
        </div>
        <h1 class="white">Christian Winkler</h1>
    </div>

    <div fxLayout="row end" fxLayoutAlign="center center">

        <a href="tel:+4917652364030" id="phone" class="white">
            <fa-icon [icon]="faPhone"></fa-icon>
        </a>
        <a href="mailto:chwinkler@chwinkler.de" id="mail" class="white">
            <fa-icon [icon]="faEnvelope"></fa-icon>
        </a>
    </div>
</header>