import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { sctFadeIn, staggerLogo, moreFadeIn } from '../animations';

@Component({
  selector: 'app-logosblock',
  templateUrl: './logosblock.component.html',
  styleUrls: ['./logosblock.component.css'],
  animations: [sctFadeIn, staggerLogo, moreFadeIn]
})
export class LogosblockComponent implements OnInit {

  sctFadeIn;
  inView;
  elRefi;

  constructor(private el: ElementRef) { }

  ngOnInit() {

    this.elRefi = this.el;
  }



}
