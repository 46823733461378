import {
  Component, OnInit, HostListener, ChangeDetectorRef, PLATFORM_ID,
  Inject, ViewChild, AfterViewInit
} from '@angular/core';
import { moreFadeIn, fadeInLefti, tadai, fromTop } from '../animations';
import { isPlatformBrowser, isPlatformServer, DOCUMENT } from '@angular/common';
import { WindowRefService } from '../window-ref.service';
import { GrafikblockComponent } from '../grafikblock/grafikblock.component';
import { ProjectsblockComponent } from '../projectsblock/projectsblock.component';
import { VpblockComponent } from '../vpblock/vpblock.component';
import { LogosblockComponent } from '../logosblock/logosblock.component';
import { BranchenComponent } from '../branchen/branchen.component';
import { MethodenComponent } from '../methoden/methoden.component';
import { PersonComponent } from '../person/person.component';
import { ConnectComponent } from '../connect/connect.component';
import { LetterblockComponent } from '../letterblock/letterblock.component';
import { PlattformService } from '../plattform.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
  animations: [moreFadeIn, fadeInLefti, tadai, fromTop]
})
export class MainComponent implements OnInit, AfterViewInit {

  newMargin = 0;
  callpopon = false;
  fadeFromTop;
  newScrollPos;
  showContent = false;
  checkOn = false;

  @ViewChild(ConnectComponent) cblock: ConnectComponent;
  @ViewChild(GrafikblockComponent) gblock: GrafikblockComponent;
  @ViewChild(ProjectsblockComponent) pblock: ProjectsblockComponent;
  @ViewChild(VpblockComponent) vpblock: VpblockComponent;
  @ViewChild(LogosblockComponent) loblock: LogosblockComponent;
  @ViewChild(BranchenComponent) bblock: BranchenComponent;
  @ViewChild(MethodenComponent) mblock: MethodenComponent;
  @ViewChild(PersonComponent) peblock: PersonComponent;
  @ViewChild(LetterblockComponent) lblock: LetterblockComponent;

  @HostListener('window:scroll', ['$event.currentTarget.pageYOffset'])
  showCallPopUp($event) {

    if ($event > 300) {

      this.callpopon = true;
    } else {

      this.callpopon = false;
    }
    /** Dieser Code wird wg. isPlatformBrowser NICHT im Server sondern lokal ausgeführt */
    // if (isPlatformBrowser(this.platformId)) {
    if (this.platformNow.thisIsPlatformBrowser) {

      const blockPositions = [this.gblock, this.pblock, this.vpblock, this.loblock, this.bblock,
      this.mblock, this.peblock, this.cblock, this.lblock];

      for (const blockPos of blockPositions) {

        const bounding = blockPos.elRefi.nativeElement.getBoundingClientRect();

        if ((bounding.top + $event) < ($event + (0.7 * this.window.nativeWindow().innerHeight))) {

          blockPos.inView = true;
        }
        // für letzte Komponente andere Einstellungen
        if ((bounding.top + $event) < ($event + (0.8 * this.window.nativeWindow().innerHeight)) && blockPos === this.cblock) {
          blockPos.inView = true;
        }
        // }
      }
    }
  }

  constructor(
    private cd: ChangeDetectorRef,
    private window: WindowRefService,
    private platformNow: PlattformService) { }

  ngOnInit() { }

  ngAfterViewInit() {
    if (this.platformNow.thisIsPlatformserver) {
      const blockPositions = [this.gblock, this.pblock, this.vpblock, this.loblock, this.bblock,
      this.mblock, this.peblock, this.cblock, this.lblock];

      for (const blockPos of blockPositions) {
        blockPos.inView = true;
      }
    }
  }


}
