import { Component, OnInit, ElementRef } from '@angular/core';
import { sctFadeIn } from '../animations';


@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.css'],
  animations: [sctFadeIn]
})
export class PersonComponent implements OnInit {
  sctFadeIn;
  inView = false;
  elRefi;

  constructor(private el: ElementRef) { }

  ngOnInit() {
    this.elRefi = this.el;
  }

}
