import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { sctFadeIn } from '../animations';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  animations: [sctFadeIn]
})
export class FooterComponent implements OnInit, AfterViewInit {
  onOff = false;
  @ViewChild('scrollIt') el: ElementRef;
  constructor() { }

  ngOnInit(): void {

  }
  ngAfterViewInit() {


  }

  showImpressum() {

    this.onOff = !this.onOff;
    if (this.onOff === true) {
      setTimeout(() => this.el.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' }), 100);
      // this.el.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

  }
}
