<section class="sect ">

    <div class="bild" [ngStyle]="{'width': '60%'}" [ngStyle.xs]="{'width': '100%'}">

        <img src="../../assets/pics/cwdigiclassic_sm.png" src.xs="../../assets/pics/cwdigiclassic_xs.png" alt="Portrait Christian Winkler digital und klassisch, Konzeption und Umsetzung">
        <div @faceFadeIn>
            <h2 id="classic" class="white">klassisch</h2>
            <h2 id="digital" class="white">digital</h2>
        </div>

    </div>
</section>