<section class="bgWhite">

    <h2 class="marBottom">Methoden und Besonderes</h2>
    <div fxLayout="row wrap" fxLayoutAlign="space-around center" *ngIf="inView === true" [@sctFadeIn]="sctFadeIn">

        <div class="bigTypo">Lean Management</div>
        <div class="bigTypo">Lean Product Development</div>
        <div class="bigTypo">Product Management</div>
        <div class="bigTypo">Lean Start-Up</div>
        <div class="bigTypo">MEAN-Stack Softwareentwicklung</div>

    </div>


</section>