<main>

    <div *ngIf="true===callpopon">
        <app-callpopup [@fromTop]="fadeFromTop"></app-callpopup>
    </div>

    <app-newfaceblock></app-newfaceblock>
    <app-mottoblock></app-mottoblock>
    <app-letterblock></app-letterblock>
    <app-grafikblock></app-grafikblock>
    <app-vpblock></app-vpblock>
    <app-projectsblock></app-projectsblock>
    <app-logosblock></app-logosblock>
    <app-branchen></app-branchen>
    <app-methoden></app-methoden>
    <app-person></app-person>
    <app-connect></app-connect>

</main>